import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { white, smallerText, smallMargin } from 'src/Styles/settings/Constants'

import { getStatusLabel } from 'src/Constants/StatusLabels'

const BadgeComponentUI = ({ status }) => {
  return (
    <BadgeContainer backgroundColor={getStatusLabel(status).color}>
      {getStatusLabel(status).label}
    </BadgeContainer>
  )
}

BadgeComponentUI.propTypes = {
  status: PropTypes.string
}

const BadgeContainer = styled.span`
    background-color: ${({ backgroundColor }) => backgroundColor};
    padding: 3px;
    border-radius:3px;
    color: ${white} !important;
    text-transform: uppercase;
    font-size: ${smallerText};
    font-weight: 700;
    margin-left: ${smallMargin};
    position: relative;
    top: -5px;
`
export default BadgeComponentUI
