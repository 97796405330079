import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactSVG from 'react-svg'
import { margin, darkGray, normalText } from 'src/Styles/settings/Constants'

const UIBankAccountIcon = ({ name, src }) => {
  return (
    <Container>
      <LabelName>{name}</LabelName><StyledReactSVG src={src} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: auto;
  align-items: center;
`

const LabelName = styled.span`
  font-size: ${normalText};
  color: ${darkGray};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const StyledReactSVG = styled(ReactSVG)`
  margin-left: ${margin};
  svg {
    height: 40px;
  }
`

UIBankAccountIcon.propTypes = {
  name: PropTypes.string,
  src: PropTypes.string
}

export default UIBankAccountIcon
