import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactSVG from 'react-svg'

import Grid from '../Grid/Grid'

import { darkerGray, bigText } from 'src/Styles/settings/Constants'

const RedirectMessage = ({ message }) => (
  <Grid noPadding>
    <RowCenter largeMarginBottom auto>
      <Icon src='/assets/icons/others/mail.svg' />
    </RowCenter>
    <RowCenter largeMarginBottom auto>
      <Text>
        {message}
      </Text>
    </RowCenter>
  </Grid>
)

RedirectMessage.propTypes = {
  message: PropTypes.string
}

const RowCenter = styled(Grid.Row)`
  text-align: center;
  justify-content: center;
`

const Icon = styled(ReactSVG)`
  svg {
    height: 3.8rem;
    width: 3.8rem;
    fill: ${darkerGray};
  }
`

const Text = styled.p`
  color: ${darkerGray};
  font-size: ${bigText};
  width: 278px;
  margin-bottom: 30px;
`

export default RedirectMessage
