import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Grid from '../Grid/Grid'

import { darkGray, normalText, smallMargin } from '../../Styles/settings/Constants'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'

const AddPos = ({ children }) => (
  <SidebarContainer>
    <Grid.Row>
      <Grid.Col cols={4}>
        <img src='/assets/imgs/pos.png' alt='imagem do pos' />
      </Grid.Col>
      <ContainerData offset={1} cols={11}>
        <Grid.Row auto>
          <Text data-test='pos-intro-text'>Busque o estabelecimento que deseja associar e digite abaixo o token exibido na tela de login do POS.</Text>
        </Grid.Row>
        <Grid.Row auto>{children}</Grid.Row>
      </ContainerData>
    </Grid.Row>
  </SidebarContainer>
)

AddPos.propTypes = {
  children: PropTypes.element
}

const ContainerData = styled(Grid.Col)`
  padding-right: ${smallMargin};
`

const Text = styled.p`
  font-size: ${normalText};
  color: ${darkGray};
`

export default AddPos
