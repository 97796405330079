import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { normalText, smallerText, white } from 'src/Styles/settings/Constants'

const Avatar = ({ fullName, backgroundColor, size }) => {
  const names = fullName.split(' ').filter(item => item)
  let namesInitial = ''
  if (names.length === 0) {
    namesInitial = `NA`
  } else if (names.length === 1) {
    namesInitial = names[0].substring(0, 2)
  } else if (names.length > 1) {
    const firstLetter = names[0][0].toUpperCase()
    const secondLetter = names[names.length - 1][0].toLowerCase()

    namesInitial = `${firstLetter}${secondLetter}`
  }

  return (
    <AvatarWrapper backgroundColor={backgroundColor} size={size}>
      <NamesInitials>{namesInitial}</NamesInitials>
    </AvatarWrapper>
  )
}

const AvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${({ backgroundColor, theme: { color } }) => backgroundColor || color};

  ${({ size }) => {
    switch (size) {
    case 'big':
      return css`
          font-size: ${normalText};
          width: 43px;
          height: 43px;
      `
    case 'small':
      return css`
          font-size: ${smallerText};
          width: 29px;
          height: 29px;
      `
    }
  }}
`

const NamesInitials = styled.span`
  color: ${white};
`

Avatar.defaultProps = {
  fullName: '',
  size: 'small'
}

Avatar.propTypes = {
  fullName: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  size: PropTypes.oneOf(['big', 'small'])
}

export default Avatar
