import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { white, lightGray, darkGray } from '../../Styles/settings/Constants'
import ReactSVG from 'react-svg'

const Card = ({
  onClick,
  children,
  buttonClose,
  handleClose,
  withShadow,
  outline,
  borderColor,
  textColor,
  padding,
  margin,
  background,
  className,
  noTheme,
  ...props
}) => {
  return (
    <CardStyle
      onClick={onClick}
      withShadow={withShadow}
      outline={outline}
      borderColor={borderColor}
      textColor={textColor}
      padding={padding}
      margin={margin}
      background={background}
      className={className}
      {...props}
    >
      {buttonClose && <CloseIcon
        data-tracking={`${props['data-tracking']} > Close`}
        noTheme={noTheme}
        onClick={handleClose}
        src='/assets/icons/actions/close.svg'
        data-test='card-close-button'
      />}
      {children}
    </CardStyle>
  )
}

const CardStyle = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: ${white};
  border-radius: 12px;
  padding: ${props => props.padding || '20px'};
  margin: ${props => props.margin || '0'};

  ${({ withShadow }) => withShadow && css`
    box-shadow: 0px 3px 7px ${lightGray};
  `}

  ${({ outline }) => outline && css`
    background-color: transparent;
    border: 1px solid;
  `}

  ${({ borderColor }) => borderColor && css`
    border-color: ${borderColor};
  `}

  ${({ background }) => background && css`
    background-color: ${background}};
  `}

  ${({ textColor }) => textColor && css`
    color: ${textColor};
  `}
`

const CloseIcon = styled(ReactSVG)`
  z-index: 1;
  color: ${props => props.theme.color};
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;

  svg {
    fill: ${props => props.noTheme ? darkGray : props.theme.color};
  }
`

Card.defaultProps = {
  withShadow: true,
  outline: false,
  noTheme: false
}

Card.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  withShadow: PropTypes.bool,
  outline: PropTypes.bool,
  borderColor: PropTypes.string,
  textColor: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  'data-tracking': PropTypes.string,
  buttonClose: PropTypes.bool,
  handleClose: PropTypes.func,
  background: PropTypes.string,
  className: PropTypes.string,
  noTheme: PropTypes.bool
}

export default Card
