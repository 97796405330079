import React from 'react'
import PropTypes from 'prop-types'

import Grid from '../Grid/Grid'
import Button from 'src/UIComponents/Button/ButtonDefault'

import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import { Text } from './AddSeller.styled'

const AddSeller = ({ individualAction, businessAction }) => (
  <SidebarContainer>
    <Grid.Row>
      <Grid.Col offset={1} cols={14}>
        <Text>Qual tipo de estabelecimento você deseja adicionar?</Text>
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col offset={1} cols={7} onClick={() => individualAction()}>
        <Button icon='/assets/icons/others/enterprise.svg' iconTop clean data-tracking='Adicionar Seller Individual' data-test='add-seller-individual-btn' >
          Pessoa Física
        </Button>
      </Grid.Col>
      <Grid.Col cols={7} onClick={() => businessAction()}>
        <Button icon='/assets/icons/others/enterprise.svg' iconTop clean data-tracking='Adicionar Seller Business' data-test='add-seller-business-btn'>
          Pessoa Jurídica
        </Button>
      </Grid.Col>
    </Grid.Row>
  </SidebarContainer>
)

AddSeller.propTypes = {
  individualAction: PropTypes.func,
  businessAction: PropTypes.func
}

export default AddSeller
