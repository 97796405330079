import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactSVG from 'react-svg'

import Grid from '../Grid/Grid'
import Label from '../Label/Label'

import { darkGray, normalText } from '../../Styles/settings/Constants'

const BlankState = ({ icon, message }) => (
  <Grid noPadding>
    <RowCenterContent auto>
      <StyleReactSVG src={icon} />
    </RowCenterContent>
    <RowCenterContent auto>
      <Label color={darkGray} fontSize={normalText} bold>{message}</Label>
    </RowCenterContent>
  </Grid>
)

BlankState.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

const RowCenterContent = styled(Grid.Row)`
  justify-content: center;
`

const StyleReactSVG = styled(ReactSVG)`
  svg {
    width: 24px;
    height: 24px;
  }
`

export default BlankState
