import React from 'react'
import PropTypes from 'prop-types'

import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import Button from 'src/UIComponents/Button/ButtonDefault'
import Card from 'src/UIComponents/Card/Card'

import { bigText, darkGray } from 'src/Styles/settings/Constants'

const AlertCloseSidebar = ({ children, labelContinue, onClickContinue, labelClose, onClickClose }) => (
  <Card>
    <Grid.Row auto>
      <Label breakLine bold textAlign='center' color={darkGray} fontSize={bigText}>
        {children}
      </Label>
    </Grid.Row>
    <Grid.Row auto>
      <Button onClick={onClickContinue}>
        {labelContinue}
      </Button>
    </Grid.Row>
    <Grid.Row auto>
      <Button inversed onClick={onClickClose}>
        {labelClose}
      </Button>
    </Grid.Row>
  </Card>
)

AlertCloseSidebar.defaultProps = {
  labelContinue: 'Continuar',
  labelClose: 'Sair'
}

AlertCloseSidebar.propTypes = {
  children: PropTypes.any,
  labelContinue: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClickContinue: PropTypes.func.isRequired,
  labelClose: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClickClose: PropTypes.func.isRequired
}

export default AlertCloseSidebar
