import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import onClickOutside from 'react-onclickoutside'

import ButtonDefault from 'src/UIComponents/Button/ButtonDefault'
import { ChevronDown, ChevronUp } from 'src/UIComponents/Chevron/Chevron'
import DisplayDropdownItems from 'src/UIComponents/ButtonDropdown/DisplayDropdownItems'

import { white, gray, darkerGray, smallText } from 'src/Styles/settings/Constants'

const ButtonDefaultDropdown = ({ options, selected, onChange, disabled, children, firstControl, secondControl, ...props }) => {
  const [isOpen, setOpen] = useState(false)
  const [isTooltip, setTooltip] = useState(false)

  ButtonDefaultDropdown.handleClickOutside = () => setOpen(false)

  const mouseEnter = () => {
    if (disabled) {
      setTooltip(true)
      setTimeout(() => setTooltip(false), 3000)
    }
  }

  const handleChange = value => {
    setOpen(false)
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <Wrapper handle={() => setOpen(false)} data-test='btn-default-dropdown'>
      <Button {...props} onMouseEnter={mouseEnter} disabled={disabled} component onClick={() => setOpen(!isOpen)}>
        { children }
        { isOpen
          ? <IconUp color={disabled ? gray : white} />
          : <IconDown color={disabled ? gray : white} />}
      </Button>
      {isTooltip && (secondControl || firstControl) &&
        <TooltipLeft>{secondControl || firstControl}</TooltipLeft>
      }
      {isOpen && (
        <DisplayDropdownItems data-tracking={`${props['data-tracking']} > list`} options={options} onChange={handleChange} selected={selected} />
      )}
    </Wrapper>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => ButtonDefaultDropdown.handleClickOutside
}

const Wrapper = styled.div`
  position: relative;
`

const TooltipLeft = styled.div`
  text-align: center;
  position: absolute;
  background: ${darkerGray};
  border-radius: 4px;
  padding: 5px;
  color: ${white};
  font-size: ${smallText};
  top: 50%;
  left: -202px;
  width: 196px;
  transform: translateY(-50%);

  :before{
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid ${darkerGray};
    right: -6px;
    top: 50%;
    transform: translateY(-50%);
}
`

// const TooltipDown = styled(TooltipLeft)`
//   top: 38px;
//   left: -14px;

//   :before{
//     width: 0;
//     border-right: 6px solid transparent;
//     border-left: 6px solid transparent;
//     border-bottom: 6px solid ${darkerGray};
//     right: 160px;
//     top: -12px;
// `

const Button = styled(ButtonDefault)`
  position: relative;
  padding-right: 3.5rem;
  white-space: nowrap;
`

const IconUp = styled(ChevronUp)`
  position: absolute;
  top: 8px;
  right: 15px;
`

const IconDown = styled(ChevronDown)`
  position: absolute;
  top: 8px;
  right: 15px;
`

ButtonDefaultDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      linkProps: PropTypes.any
    }).isRequired
  ).isRequired,
  selected: PropTypes.number,
  onChange: PropTypes.func,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  firstControl: PropTypes.string,
  secondControl: PropTypes.string
}

const Menu = onClickOutside(ButtonDefaultDropdown, clickOutsideConfig)

export { Menu }
