import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { toMoney } from 'src/Utils/Utils'
import BalanceSellerLoader from './BalanceSellerLoader'
import { white, bigText } from 'src/Styles/settings/Constants'
import BalanceSellerError from '../../UIComponents/BalanceSeller/BalanceSellerError'
import { biggerText, darkGray, gray, green, margin, normalText, red, smallerText, yellow } from '../../Styles/settings/Constants'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Label from '../Label/Label'
import { Tooltip } from '../Tooltip'

const BalanceSellerUI = ({
  shortView,
  currentBalance,
  blockedBalance,
  currentBalanceRequesting,
  blockedBalanceRequesting,
  currentBalanceError,
  blockedBalanceError
}) => {
  const [showBalance, setShowBalance] = useState(() => {
    const hideBalance = sessionStorage.getItem('hideBalance')
    return hideBalance === 'true' // Convert string to boolean
  })

  if (shortView) {
    return (
      <BalanceResumeContainer>
        <BalanceContainer shortView={shortView}>
          <Amount shortView={shortView}>
            {currentBalanceRequesting
              ? <BalanceSellerLoader />
              : currentBalanceError
                ? <BalanceSellerError resource='currentBalance' />
                : <BudgetContainer>
                  <Label color={gray} fontSize={smallerText}>Saldo disponível na <strong>conta digital</strong></Label>
                  <Label
                    color={showBalance ? currentBalance >= 0 ? green : red : null}
                    textAlign={showBalance ? 'left' : 'center'}
                    width='100%'
                    bold
                    fontSize={biggerText}
                  >
                    {showBalance ? toMoney(currentBalance / 100) : '-'}
                  </Label>
                </BudgetContainer>
            }
          </Amount>
          <EyeButton shortView={shortView} onClick={() => {
            setShowBalance((prev) => {
              sessionStorage.setItem('hideBalance', !prev)
              return !prev
            })
          }}>
            <FontAwesomeIcon color={'#fff'} size={'2x'}icon={showBalance ? faEye : faEyeSlash} />
          </EyeButton>
        </BalanceContainer>
      </BalanceResumeContainer>
    )
  }

  return (
    <BalanceResumeContainer>
      <Label color={gray} marginRight={2} textAlign='right'>Informações sujeitas a <br /><b>variações</b> no decorrer do dia</Label>
      <BalanceContainer>
        <Label color={gray} marginLeft={2} fontSize={normalText}><b>Resumo</b> da conta</Label>
        <Separator />
        <Amount>
          {currentBalanceRequesting
            ? <BalanceSellerLoader />
            : currentBalanceError
              ? <BalanceSellerError resource='currentBalance' />
              : <BudgetContainer>
                <Label color={gray} fontSize={smallerText}>Saldo disponível</Label>
                <Label
                  color={showBalance ? currentBalance >= 0 ? green : red : null}
                  textAlign={showBalance ? 'left' : 'center'}
                  width='100%'
                  bold
                  fontSize={biggerText}
                >
                  {showBalance ? toMoney(currentBalance / 100) : '-'}
                </Label>
              </BudgetContainer>
          }
        </Amount>
        <Separator />
        <Amount>
          {blockedBalanceRequesting
            ? <BalanceSellerLoader resource='blockedBalance' />
            : blockedBalanceError
              ? <BalanceSellerError />
              : <BudgetContainer>
                <Label color={gray} fontSize={smallerText}>
                  <LabelWithTooltip>Saldo Agendado <InfoIcon data-tip data-for='teste-tooltip'>?</InfoIcon></LabelWithTooltip>
                  <Tooltip id='teste-tooltip'>Disponível no próximo dia útil</Tooltip>
                </Label>
                <Label
                  color={showBalance ? yellow : null}
                  textAlign={showBalance ? 'left' : 'center'}
                  width='100%'
                  bold
                  fontSize={biggerText}
                >
                  {showBalance ? toMoney(blockedBalance / 100) : '-'}
                </Label>
              </BudgetContainer>
          }
        </Amount>
        <EyeButton onClick={() => {
          setShowBalance((prev) => {
            sessionStorage.setItem('hideBalance', !prev)
            return !prev
          })
        }}>
          <FontAwesomeIcon color={'#fff'} size={'2x'}icon={showBalance ? faEye : faEyeSlash} />
        </EyeButton>
      </BalanceContainer>
    </BalanceResumeContainer>
  )
}

BalanceSellerUI.propTypes = {
  shortView: PropTypes.bool,
  currentBalance: PropTypes.string,
  blockedBalance: PropTypes.string,
  currentBalanceRequesting: PropTypes.bool,
  blockedBalanceRequesting: PropTypes.bool,
  currentBalanceError: PropTypes.any,
  blockedBalanceError: PropTypes.any
}

const BalanceContainer = styled.div`
  border-radius: 8px;
  background-color: ${white};
  display: flex;
  align-items: center;
  box-shadow: 0px 7px 9px 0px rgba(0, 0, 0, 0.07);
  height: 100%;
  overflow: hidden;

  ${(props) => props.shortView && css`
    width: 100%;
  `}
`

const BalanceResumeContainer = styled.div`
  display: flex;
  align-items: center;
`

const EyeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 62px;
  background: ${(props) => props.color || props.theme.color};
  padding: 1rem;
  margin-left: ${margin};
  min-width: 50px;

  ${(props) => props.shortView && css`
    margin-left: auto;
  `}

  svg{
    width: 18px !important;
  }

  &:hover{
    cursor: pointer;
  }
`

const BudgetContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
`

const Separator = styled.span`
&:after {
  display: block;
  content: "";
  height: 19px;
  width: 1px;
  background: white;
  border-left:1px solid #B8B8B8;
  margin-left: ${margin};
}
`

const Amount = styled.span`
  color: ${(props) => props.color || props.theme.color};
  font-size: ${bigText};
  margin-left: ${margin};
  
  ${(props) => props.shortView && css`
    width: 100%;
  `}
  
  > strong {
    font-weight: bold
  }
`

const LabelWithTooltip = styled.div`
  display: flex;
`

const InfoIcon = styled.div`
  padding: 0px 4px;
  background-color: ${darkGray};
  color: ${white};
  border-radius: 3px;
  margin-left: 5px;
  font-size: 10px;
`

export default BalanceSellerUI
