import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactSVG from 'react-svg'
import { Link } from 'react-router-dom'

import Button from '../Button/ButtonDefault'
import AuthBox from './AuthBox'
import Grid from '../Grid/Grid'

import { green, darkerGray, bigText } from 'src/Styles/settings/Constants'

const AuthStatusMessage = ({ title, message, labelButton, path, type }) => (
  <AuthBox title={title}>
    <Grid noPadding>
      <RowCenter largeMarginBottom auto>
        {type === 'error' && <IconError src='/assets/icons/actions/close.svg' />}
        {type === 'success' && <IconSuccess src='/assets/icons/system/check.svg' />}
      </RowCenter>
      <RowCenter largeMarginBottom auto>
        <Text>
          {message}
        </Text>
      </RowCenter>
      <RowCenter auto>
        <LinkBox to={path}>
          <Button>
            {labelButton}
          </Button>
        </LinkBox>
      </RowCenter>
    </Grid>
  </AuthBox>
)

AuthStatusMessage.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  labelButton: PropTypes.string,
  path: PropTypes.string,
  title: PropTypes.string
}

const RowCenter = styled(Grid.Row)`
  text-align: center;
  justify-content: center;
`

const IconError = styled(ReactSVG)`
  svg {
    height: 3.8rem;
    width: 3.8rem;
    fill: ${darkerGray};
  }
`

const IconSuccess = styled(ReactSVG)`
  svg {
    height: 5.4rem;
    width: 5.4rem;
    fill: ${green};
  }
`

const Text = styled.div`
  color: ${darkerGray};
  font-size: ${bigText};
  width: 295px;
`

const LinkBox = styled(Link)`
  color: transparent;
`

export default AuthStatusMessage
