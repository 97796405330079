import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'

import Grid from '../Grid/Grid'
import { red, yellow, darkGray, textBold, smallText, smallMargin, margin } from 'src/Styles/settings/Constants'

const AlertMessage = ({ type, error, noMargin, noCode, info }) => {
  let title, message
  if (type === 'error' || type === 'warning') {
    if (typeof error.message === 'object') {
      title = error.message.title
      message = error.message.message
    } else {
      title = error.message
      message = 'Por favor, tente novamente.'
    }
  } else {
    title = info.title
    message = info.message
  }

  return (
    <Container noMargin={noMargin} type={type}>
      <Icon src='/assets/icons/alerts/alert.svg' type={type} />
      <div>
        {type === 'error' && (
          <TextBold type={type}>{title} {!noCode && error.status ? `(erro ${error.status})` : ''}</TextBold>
        )}
        {type === 'warning' && (
          <TextBold type={type}>{title}</TextBold>
        )}
        {type === 'info' && (
          <TextBold type={type}>{title}</TextBold>
        )}
        <Text type={type}>{message}</Text>
      </div>
    </Container>
  )
}

AlertMessage.propTypes = {
  type: PropTypes.oneOf(['error', 'warning', 'info']),
  error: PropTypes.object,
  noMargin: PropTypes.bool,
  noCode: PropTypes.bool,
  info: PropTypes.bool
}

const Container = styled(Grid.Row)`
  display: flex;
  width: 100%;
  margin: ${margin} 0;
  padding: ${smallMargin} ${margin};

  ${({ type }) => type === 'error' && css`
    border: 1px solid ${red};
    border-radius: 5px;
  `}

  ${({ type }) => type === 'warning' && css`
    border: 1px solid ${yellow};
    border-radius: 5px;
  `}

  ${({ type }) => type === 'info' && css`
  border: 1px solid ${darkGray};
  border-radius: 5px;
`}

  ${({ noMargin }) => noMargin && css`
    margin: 0;
  `}
`

const Icon = styled(ReactSVG)`
  align-self: center;
  margin-right: ${smallMargin};

  svg {
    fill: ${red};
    width: 19px;
    height: 19px;
   }

  ${({ type }) => type === 'warning' && css`
    svg {
      fill: ${yellow};
    }
  `}
  ${({ type }) => type === 'info' && css`
  svg {
    fill: ${darkGray};
  }
`}
`

const TextBold = styled.p`
  font-weight: ${textBold};
  font-size: ${smallText};
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  ${({ type }) => type === 'error' && css`
    color: ${red};
  `}

  ${({ type }) => type === 'warning' && css`
    color: ${yellow};
  `}
`

const Text = styled.p`
  font-size: ${smallText};

  ${({ type }) => type === 'error' && css`
    color: ${red};
  `}

  ${({ type }) => type === 'warning' && css`
    color: ${yellow};
  `}
`

export default AlertMessage
