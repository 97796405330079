import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Logo from '../../Components/LogoComponent/LogoComponent'
import Grid from '../Grid/Grid'
import Card from '../Card/Card'
import Title from '../Title/Title'
import Label from '../Label/Label'
import { biggerText } from '../../Styles/settings/Constants'

const AuthBoxDoubleColumn = ({ focusTitle, title, children }) => (
  <>
    <Wrapper>
      <AuthBoxWrapper>
        <Grid noPadding>
          <LogoRow largeMarginBottom auto>
            <Logo big />
          </LogoRow>
          <CardBox>
            <Grid.Row largeMarginBottom auto>
              <Title noPadding big uppercase bar={false} >
                <Label fontSize={biggerText} big marginRight={'1'}>{focusTitle}</Label>
                {title}
              </Title>
            </Grid.Row>
            <MainContainer style={{ width: '100%' }}>
              <Grid.Row auto>
                {children[0]}
              </Grid.Row>
              <Grid.Row auto>
                {children[1]}
              </Grid.Row>
            </MainContainer>
          </CardBox>
        </Grid>
      </AuthBoxWrapper>
    </Wrapper>
  </>
)

AuthBoxDoubleColumn.defaultProps = {
  title: 'Acesse sua conta'
}

AuthBoxDoubleColumn.propTypes = {
  focusTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.any
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;
  padding: 8rem;
`

const AuthBoxWrapper = styled.div`
  width: 900px;
`

const CardBox = styled(Card)`
  padding: 5.3rem 5.6rem 5rem 5.6rem;
`

const LogoRow = styled(Grid.Row)`
  text-align: center;
`

const MainContainer = styled(Grid.Row)`
  grid-template-columns: repeat(2, 1fr);
  gap: 6rem;
`

export default AuthBoxDoubleColumn
