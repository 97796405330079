import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { white, darkGray, darkerGray, lightGray, normalText, textBold } from 'src/Styles/settings/Constants'

const DisplayDropdownItems = ({ options, selected, onChange, ...props }) => (
  <List>
    {options.map(item => (
      <ListItem
        data-tracking={`${props['data-tracking']} > item > ${item.label}`}
        data-test={`display-dropdown-items-${item.value}`}
        key={item.value}
        selected={selected}
        onClick={() => onChange(item.value)}
      >
        {item.label}
      </ListItem>
    ))}
  </List>
)

const List = styled.ul`
  list-style-type: none;
  background-color: ${white};
  position: absolute;
  border: 1px solid ${lightGray};
  box-sizing: border-box;
  box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.07);
  border-radius: 5px 5px 5px 5px;
  padding: 10px 0;
  margin-top: 5px;
  width: 165px;
  z-index: 1;
  right: 0;
`

const ListItem = styled.li`
  cursor: pointer;
  font-size: ${normalText};
  outline: none;
  margin: 4px 0;
  background: 0;
  color: ${darkGray};
  border: 0 solid transparent;
  border-right-width: 2px;
  border-left-width: 2px;
  padding: 0 10px;
  line-height: 2;

  :hover {
    color: ${darkerGray};
    border-left-color: ${({ theme }) => theme.color};
    font-weight: ${textBold};
  }

  ${({ selected }) => selected & css`
    color: ${darkerGray};
    font-weight: ${textBold};
  `}
`

DisplayDropdownItems.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      linkProps: PropTypes.any
    }).isRequired
  ).isRequired,
  selected: PropTypes.number,
  'data-tracking': PropTypes.string,
  onChange: PropTypes.func
}

export default DisplayDropdownItems
