import React from 'react'
import styled from 'styled-components'
import { yellow } from '../../Styles/settings/Constants'

const BackofficeDisclaimer = () => {
  return (
    <Container>Este acesso é via BackOffice. Tenha cuidado com a modificação dos dados</Container>
  )
}

export { BackofficeDisclaimer }

const Container = styled.div`
  width: 100%;
  z-index: 999;
  text-align: center;
  background-color: ${yellow};
  padding: 5px 0;
  font-weight: bold;
  font-size: 12px;
`
