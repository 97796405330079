import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'

import Dots from '../Dots/Dots'

import {
  buttonFontSize,
  normalText,
  textBold,
  darkGray,
  darkerGray,
  white,
  black,
  lightGray,
  gray,
  middlePadding,
  spaceMargin,
  smallMargin } from 'src/Styles/settings/Constants'

const ButtonDefault = (
  {
    type,
    onClick,
    children,
    isLoading,
    link,
    ghost,
    inversed,
    clean,
    icon,
    outline,
    outlineColor,
    iconTop,
    upperCase,
    colorButton,
    widthAuto,
    disabled,
    component,
    className,
    color,
    ...props
  }) => {
  const label = () => {
    if (children) {
      if (upperCase) {
        children = children.toUpperCase()
      }
      if (clean || link) {
        return <Bold>{children}</Bold>
      }
      if (component) {
        return children
      } else {
        const words = children.split(' ')
        const firstWord = words[0]
        words.shift()

        return (
          <Fragment>
            {upperCase && <Bold>{children}</Bold>}
            {!upperCase && (
              <Fragment>
                <Bold length={words.length}>{firstWord}</Bold>
                {words.length > 0 ? words.join(' ') : ''}
              </Fragment>
            )}
          </Fragment>
        )
      }
    } else {
      return ''
    }
  }

  return (
    <Button
      {...props}
      type={type}
      onClick={(e) => onClick && onClick(e)}
      link={link}
      ghost={ghost}
      inversed={inversed}
      clean={clean}
      isLoading={isLoading}
      outline={outline}
      outlineColor={outlineColor}
      icon={icon}
      iconTop={iconTop}
      upperCase={upperCase}
      widthAuto={widthAuto}
      colorButton={colorButton}
      disabled={disabled}
      component={component}
      className={className}
      color={color}
    >
      {isLoading && <Dots outline={outline || ghost || inversed || clean || link} color={colorButton} light />}
      {!isLoading && icon && <Icon iconTop={iconTop} src={icon} />}
      {!isLoading && label()}
    </Button>
  )
}

ButtonDefault.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  inversed: PropTypes.bool,
  clean: PropTypes.bool,
  link: PropTypes.bool,
  ghost: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  widthAuto: PropTypes.bool,
  colorButton: PropTypes.string,
  outline: PropTypes.bool,
  icon: PropTypes.string,
  iconTop: PropTypes.bool,
  upperCase: PropTypes.bool,
  component: PropTypes.bool,
  color: PropTypes.string,
  outlineColor: PropTypes.string,
  nowrap: PropTypes.bool
}

const Icon = styled.i.attrs(({ iconTop, ...props }) => ({ children: <ReactSVG {...props} />, iconTop }))`
  display: ${({ iconTop }) => iconTop ? 'block' : 'inline-block'};
  width: ${({ iconTop }) => iconTop ? '1.7rem' : normalText};
  height: ${({ iconTop }) => iconTop ? '1.7rem' : normalText};
  margin: ${({ iconTop }) => iconTop ? 'auto' : `0 ${spaceMargin} 0 0`};
  margin-bottom: ${({ iconTop }) => iconTop ? smallMargin : 0};

  svg {
    fill: ${white};
    width: ${({ iconTop }) => iconTop ? '1.7rem' : normalText};
    height: ${({ iconTop }) => iconTop ? '1.7rem' : normalText};
  }
`

const Button = styled.button`
  background-color: ${({ colorButton, theme: { color } }) => colorButton || color};
  border-radius: 5px;
  border: none;
  padding: 0.8rem ${middlePadding};
  font-size: ${buttonFontSize};
  color: ${white};
  width: ${({ widthAuto }) => widthAuto ? 'auto' : '100%'};
  line-height: 1;
  cursor: pointer;
  position: relative;
  white-space: ${({ nowrap }) => nowrap ? 'nowrap' : 'normal'}};

  &:hover:not([disabled]) {
    box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.11);
    transition: all ease-in-out 0.3s;
  }

  &:not([disabled]):hover::after,
  &:not([disabled]):active::after {
    opacity: 0.2;
  }

  &:not([disabled]):after {
    opacity: 0;
    transition: all ease-in-out 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: ${black};
    mix-blend-mode: overlay;
    border-radius: 5px;
  }

  &:not([disabled]):active {
    box-shadow: none;
  }

  :disabled {
    color: ${gray};
    background-color: #F1F1F1;
    cursor: not-allowed;

    ${Icon} {
      svg {
        fill: ${gray};
      }
    }
  }

  :focus {
    outline: none;
  }

  ${({ inversed }) => inversed && css`
    background-color: transparent;
    border: 1px solid ${darkGray};
    color: ${darkGray};

    ${Icon} {
      svg {
        fill: ${darkGray};
      }
    }

    &:not([disabled]):hover {
      background-color: ${({ colorButton }) => colorButton || darkerGray};
      color: ${white};
      border: 1px solid ${darkerGray};

      ${Icon} {
        svg {
          fill: ${white};
        }
      }
    }

    :disabled {
      border: 1px solid ${gray};
    }
  `}

  ${({ clean }) => clean && css`
    background-color: ${white};
    border-radius: 10px;
    color: ${darkerGray};

    ${Icon} {
      svg {
        fill: ${darkerGray};
      }
    }

    &:not([disabled]):hover {
      box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.11);
    }

    &:not([disabled]):hover:after {
      border-radius: 10px;
    }

    &:not([disabled]):hover:active {
      box-shadow: 0 0 0 transparent;
    }

    &:not([disabled]):active {
      background-color: ${({ colorButton, theme: { color } }) => colorButton || color};
      color: ${white};

      ${Icon} {
        svg {
          fill: ${white};
        }
      }
    }
  `}

  ${({ ghost }) => ghost && css`
    background-color: transparent;
    color: ${({ colorButton, theme: { color } }) => colorButton || color};

    ${Icon} {
      svg {
        fill: ${({ colorButton, theme: { color } }) => colorButton || color};
      }
    }

    &:not([disabled]):hover {
      background-color: #F1F1F1;
      box-shadow: 0 0 0 transparent;
    }



    &:not([disabled]):hover:active {
      background-color: ${lightGray};
      box-shadow: 0 0 0 transparent;
    }

    :disabled {
      background-color: transparent;
    }
  `}

  ${({ link }) => link && css`
    background-color: transparent;
    color: ${({ colorButton, theme: { color } }) => colorButton || color};
    padding-left: 0;
    padding-right: 0;

    ${Icon} {
      svg {
        fill: ${({ colorButton, theme: { color } }) => colorButton || color};
      }
    }

    &:not([disabled]):hover::after,
    &:not([disabled]):active::after {
      opacity: 0;
    }

    &:not([disabled]):hover,
    &:not([disabled]):active {
      box-shadow: 0 0 0 transparent;
    }

    :disabled {
      background-color: transparent;
    }
  `}

  ${({ outline }) => outline && css`
    width: ${({ widthAuto }) => widthAuto ? 'auto' : '100%'};
    &:not([disabled]) {
      border: 1px solid ${({ outlineColor, colorButton, theme: { color } }) => outlineColor || colorButton || color};
      svg {
        fill: ${({ outlineColor, colorButton, theme: { color } }) => outlineColor || colorButton || color};
      }
    }

    :disabled {
      border: 1px solid ${gray};
    }
  `}

  ${({ isLoading }) => isLoading && css`
    pointer-events: none;
  `}

  ${({ color }) => color && css`
    color: ${color};
  `}
`

const Bold = styled.span`
  font-weight: ${textBold};
  margin-right: ${({ length }) => length > 0 ? spaceMargin : 0};
`

export default ButtonDefault
