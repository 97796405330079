import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Logo from '../../Components/LogoComponent/LogoComponent'
import Grid from '../Grid/Grid'
import Card from '../Card/Card'
import Title from '../Title/Title'

const AuthBox = ({ title, children }) => (
  <>
    <Wrapper>
      <AuthBoxWrapper>
        <Grid noPadding>
          <LogoRow largeMarginBottom auto>
            <Logo big />
          </LogoRow>
          <CardBox>
            <Grid.Row largeMarginBottom auto>
              <Title noPadding big uppercase bar={false} themeColor>{title}</Title>
            </Grid.Row>
            <Grid.Row style={{ width: '100%' }}>
              <Grid.Col cols={16}>
                {children}
              </Grid.Col>
            </Grid.Row>
          </CardBox>
        </Grid>
      </AuthBoxWrapper>
    </Wrapper>
  </>
)

AuthBox.defaultProps = {
  title: 'Acesse sua conta'
}

AuthBox.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.any
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;
  padding: 8rem;
`

const AuthBoxWrapper = styled.div`
  width: 450px;
`

const CardBox = styled(Card)`
  padding: 5.3rem 5.6rem 5rem 5.6rem;
`

const LogoRow = styled(Grid.Row)`
  text-align: center;
`

export default AuthBox
