import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { getCardBrandImg, getPaymentType, getPaymentTypeIcon } from '../../Utils/Utils'
import { darkerGray, normalText } from 'src/Styles/settings/Constants'

const CardBrandDisplay = ({ src, paymentType, installmentPlan, center, fontText, displayColumn }) => (
  <Container center={center} displayColumn={displayColumn}>
    {paymentType === 'wallet' || paymentType === 'voucher' || paymentType === 'commission' || paymentType === 'pix' || paymentType === 'nupay' || paymentType === 'boleto' || paymentType === 'bolepix'
      ? <Image src={getPaymentTypeIcon(paymentType)} />
      : <Image src={getCardBrandImg(src)} />}
    <Text fontText={fontText}>{paymentType && getPaymentType(paymentType, installmentPlan)} </Text>
  </Container>
)

CardBrandDisplay.propTypes = {
  src: PropTypes.string.isRequired,
  paymentType: PropTypes.string,
  installmentPlan: PropTypes.string,
  center: PropTypes.bool,
  displayColumn: PropTypes.bool,
  fontText: PropTypes.string
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  ${({ center }) => center && css`
    justify-content: center;
  `}

  ${({ displayColumn }) => displayColumn && css`
    align-items: flex-start;
    flex-direction: column;
  `}
`

const Text = styled.span`
  display: flex;
  color: ${darkerGray};
  font-size: ${normalText};

  ${({ fontText }) => fontText && css`
    font-size: ${fontText};
  `}
`

const Image = styled.img`
  margin-right: 5px;
`

export default CardBrandDisplay
