import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { buttonHeight, buttonHeightFit, buttonFontSize, textBold, darkGray, darkerGray, white, middlePadding } from 'src/Styles/settings/Constants'

const Button = ({ type, onClick, black, white, fit, border, children, className, outline, ...props }) => {
  const words = children.split(' ')
  const lastWord = words[words.length - 1]
  words.pop()

  return (
    <ButtonDefault
      black={black}
      white={white}
      border={border}
      type={type}
      onClick={(e) => onClick && onClick(e)}
      className={className}
      outline={outline}
      fit={fit}
      {...props}
    >
      {words.length > 0 ? words.join(' ') : ''} <Bold>{lastWord}</Bold>
    </ButtonDefault>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  children: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  black: PropTypes.bool,
  white: PropTypes.bool,
  border: PropTypes.bool,
  outline: PropTypes.bool,
  fit: PropTypes.bool
}

const ButtonDefault = styled.button`
  cursor: pointer;
  background-color: ${({ theme: { color } }) => color};
  border-radius: 5px;
  border: none;
  padding: ${middlePadding};
  font-size: ${buttonFontSize};
  height: ${buttonHeight};
  margin: 0 0.25rem;
  white-space: nowrap;
  color: ${white};
  width: 100%;
  line-height: 0;
  cursor: pointer;

  ${({ fit }) => fit && css`
    height: ${buttonHeightFit};
  `}

  ${({ border }) => border && css`
    border: 1px solid ${white};
  `}

  ${({ black }) => black && css`
    background-color: ${darkerGray};
  `}

  ${({ white }) => white && css`
    border: 1px solid ${darkGray};
    background-color: transparent;
    color: ${darkGray};
  `}

  ${({ outline }) => outline && css`
    background-color: transparent;
    color: ${({ theme: { color } }) => color};
  `}

  :disabled {
    opacity: .5
  }

  :focus {
    outline: none;
  }

  ${({ card }) => card && css`
    background-color: transparent;
    color: ${({ theme: { color } }) => color};
  `}

  :disabled {
    opacity: .5
  }

  :focus {
    outline: none;
  }

`

const Bold = styled.span`
  font-weight: ${textBold};
`

export default Button
