import React from 'react'

import Proptypes from 'prop-types'
import styled from 'styled-components'
import ReactSVG from 'react-svg'
import { useDispatch } from 'react-redux'

import { getBalanceStats } from 'src/Actions/balances'
import { getBlockedBalanceStatsFromGetBalances } from '../../Actions/balances'

const BalanceSellerErrorUI = ({ resource }) => {
  const dispatch = useDispatch()

  const reloadBalance = async () => {
    if (resource === 'currentBalance') {
      return await dispatch(getBalanceStats())
    } else {
      return await dispatch(getBlockedBalanceStatsFromGetBalances())
    }
  }

  return (
    <Container onClick={reloadBalance}>
      <span>Recarregar</span>
      <ReloadIcon src='/assets/icons/others/reload.svg' />
    </Container>
  )
}

BalanceSellerErrorUI.propTypes = {
  resource: Proptypes.string
}

const ReloadIcon = styled(ReactSVG)`
  z-index: 1;
  margin-left: 10px;
  cursor: pointer;
  svg {
    fill: red;
  }
`

const Container = styled.div`
  display: flex;
  cursor: pointer;
  > span {
    font-weight: bold;
    color: var(--red);
  }
`

export default BalanceSellerErrorUI
