import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import Card from '../Card/Card'

import {
  textBold,
  gray,
  normalText,
  middleMargin,
  darkGray
} from '../../Styles/settings/Constants'

const CardConfig = ({ links, profile }) => (
  <Container withShadow>
    <ContainerList>
      <List>
        {profile !== 'full_access_operation' && (
          <>
            <HeaderList>Configurações </HeaderList>
              {links[0].map((item) => (
                <Item key={item.id} space>
                  <NavLink
                    data-test={`config-menu-${item.id}`}
                    data-tracking={`Config > Menu > ${item.to}`}
                    to={item.to}
                    activeclassname='active'
                  >
                    <ItemContainer>
                      <LinkText>{item.title}</LinkText>
                    </ItemContainer>
                  </NavLink>
                </Item>
              ))}

            <HeaderList>Gestão de estabelecimentos</HeaderList>

              {links[1].map((item) => (
                <Item key={item.id} space>
                  <NavLink
                    data-test={`config-menu-${item.id}`}
                    data-tracking={`Config > Menu > ${item.to}`}
                    to={item.to}
                    activeclassname='active'
                  >
                    <ItemContainer>
                      <LinkText>{item.title}</LinkText>
                    </ItemContainer>
                  </NavLink>
                  <NewBadge><div>Novo</div></NewBadge>
                </Item>
              ))}
          </>
        )}
      </List>
    </ContainerList>
  </Container>
)

CardConfig.propTypes = {
  links: PropTypes.array,
  profile: PropTypes.string
}

const Container = styled(Card)`
  align-items: flex-start;
  border-radius: 7px;
  min-width: 190px;
  position: fixed;
  width: 17%;
`

const HeaderList = styled.div`
  color: ${gray};
  font-size: ${normalText};
  margin-bottom: ${middleMargin};
`

const ContainerList = styled.div`
  width: 100%;
  padding: 0;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-left: 2px;
`

const Item = styled.li`
  display: flex;
  margin-bottom: ${middleMargin};
  position: relative;
  align-items: center;
  gap:10px;

  a:hover {
    text-decoration: none;
    font-weight: ${textBold};
    color: ${darkGray};
  }

  a:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: -23px;
    width: 3px;
    height: 18px;
    background-color: ${props => props.theme.color};
  }

  ${({ space }) => space && css`
    margin-left: 10px;
    a:hover::after {
      left: -33px;
    }
  `}

  .active {
    position: relative;
    span {
      color: ${props => props.theme.color};
      font-weight: ${textBold};
    }
    &:before{
      position: absolute;
      right: -23px;
      top: 3px;
      content: url('../../assets/icons/arrow/arrow-right.svg');
      display: flex;
    }
    &:after {
      display: none;
    }
  }
`

const LinkText = styled.span`
  color: ${gray};
  font-size: ${normalText};
  text-transform: uppercase;
  white-space: break-spaces;

  ${({ onClick }) => onClick && css`
    cursor: pointer;
  `}
`

const ItemContainer = styled.div`
  white-space: pre;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
`
const NewBadge = styled.div`
  width: auto;
  height: 30px;
  padding: 2px;
  border-radius: 5px;
  display:flex;
  align-items:center;
  background-color: ${props => props.theme.color};
  border: 1px solid #FFFFFF;

  div{
    width: 100%;
    border-radius: 5px;
    padding: 2px 8px 2px 8px;
    border: 1px solid #FFFFFF;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color:#FFFFFF;
    background-color: ${props => props.theme.color};
}
`

export default CardConfig
